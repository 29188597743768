import './Home.css'
import img_italy from '../../assets/img_italy2.png'
import cocinero from '../../assets/sombrero-cocinero.webp'
import objetivo_general from '../../assets/cocinando.webp'
import objetivo_especifico from '../../assets/clasificacion.webp'
import AOS from 'aos';
import 'aos/dist/aos.css';
import Carrousel from '../carrousel/carrousel';


const Home = () => {
    AOS.init();

 return (
    <>
        <div class="container_main">
   <div class="seccion_a">
     <div class="container_uno">
       <img src={img_italy} alt='img_italy' width="400px" className="img_italy"/>
         <div className="a_container">
          <h1  className="title_a">COCINA ÍTALO - </h1>
           <h1 className="title_a_2">CHILENA</h1>
     </div>
     <div className="b_container">
      <h2>¿QUIENES SOMOS? </h2>
      <p>Nuestros productos son desarrollados directamente por nuestro Chef Ítalo-Chileno José Costaguta,empírico en la gastronomía Genovesa Italiana, siguiendo fielmente la tradición de la cocina italiana clásica, simultáneamente fusionando esas técnicas con la gastronomía chilena se genera un producto único con nuestro sello auténtico de Empresas Gastronómicas AureoFoods Spa.</p>
     </div>

     <div className="contact_seccion">
     <a href="https://wa.me/+56967305853"className='btn_logo_wsp' aria-label="wsap" title="Whatsapp">
        <button className="btn_contact btn-contact-padding">
            <p>Contáctanos!</p>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-whatsapp" viewBox="0 0 16 16">
                <path d="M13.601 2.326A7.854 7.854 0 0 0 7.994 0C3.627 0 .068 3.558.064 7.926c0 1.399.366 2.76 1.057 3.965L0 16l4.204-1.102a7.933 7.933 0 0 0 3.79.965h.004c4.368 0 7.926-3.558 7.93-7.93A7.898 7.898 0 0 0 13.6 2.326zM7.994 14.521a6.573 6.573 0 0 1-3.356-.92l-.24-.144-2.494.654.666-2.433-.156-.251a6.56 6.56 0 0 1-1.007-3.505c0-3.626 2.957-6.584 6.591-6.584a6.56 6.56 0 0 1 4.66 1.931 6.557 6.557 0 0 1 1.928 4.66c-.004 3.639-2.961 6.592-6.592 6.592zm3.615-4.934c-.197-.099-1.17-.578-1.353-.646-.182-.065-.315-.099-.445.099-.133.197-.513.646-.627.775-.114.133-.232.148-.43.05-.197-.1-.836-.308-1.592-.985-.59-.525-.985-1.175-1.103-1.372-.114-.198-.011-.304.088-.403.087-.088.197-.232.296-.346.1-.114.133-.198.198-.33.065-.134.034-.248-.015-.347-.05-.099-.445-1.076-.612-1.47-.16-.389-.323-.335-.445-.34-.114-.007-.247-.007-.38-.007a.729.729 0 0 0-.529.247c-.182.198-.691.677-.691 1.654 0 .977.71 1.916.81 2.049.098.133 1.394 2.132 3.383 2.992.47.205.84.326 1.129.418.475.152.904.129 1.246.08.38-.058 1.171-.48 1.338-.943.164-.464.164-.86.114-.943-.049-.084-.182-.133-.38-.232z"></path>
            </svg>
        </button>
        </a>
     </div>
    </div>
    <div className="container_dos">
    </div>
  </div>  
  <div className="seccion_b" data-aos="fade-up"  data-aos-duration="2000">   
    <h1>SOBRE AUREOFOODS SPA</h1>
      <img src={img_italy} alt='about_bruno' width="300px" className='img_italy'/>
    <div className="seccion_b_1">
      
       <div className="objetivos" data-aos="fade-up"  data-aos-duration="2000">
        <img src={cocinero} width="80px" height="80px" alt='about_bruno'/>
        <h1>MISIÓN:</h1>
        <p className="text_obj"> Ser reconocida como una organización versátil, que cumpla con las expectativas de calidad y distribución logística a nivel nacional. </p>
       </div>
       <div className="objetivos" data-aos="fade-up"data-aos-duration="2000">
        <img src={objetivo_general} width="80px"height="80px"  alt='about_bruno'/>
        <h1>OBJETIVO GENERAL:</h1>
        <p className="text_obj">
        Aplicar ser un referente de la industria gastronómica, proporcionando conocimientos mixturados en cocina Ítalo-Chilena cumpliendo con la normativa sanitaria vigente en un espacio autorizado por las entidades fiscalizadoras de salud.</p>
       </div>
       <div className="objetivos"data-aos="fade-up"data-aos-duration="2000">
        <img src={objetivo_especifico} width="80px"height="80px"  alt='about_bruno'/>
        <h1>OBJETIVOS ESPECÍFICOS:</h1>
        <p className="text_obj"> 
           Consolidar nuestra marca con los clientes a fin. 
           Aplicar las estrategias de marketing y plazas para posicionar nuestros productos alimenticios. </p>
       </div>
    </div>  
  </div>
  <Carrousel/>
  </div>




    </>

    


 )


}



export default Home