import React, { useRef, useState } from 'react';
import './Contacto.css';
import { MDBIcon } from 'mdb-react-ui-kit';
import AOS from 'aos';
import 'aos/dist/aos.css';
import emailjs from '@emailjs/browser';
import img_italy from '../../assets/img_italy2.png';

const Contacto = () => {
  AOS.init();

  const form = useRef();
  const [error, setError] = useState(null);
  const [formData, setFormData] = useState({
    user_name_empresa: '',
    user_name: '',
    user_email: '',
    user_fono: '',
    message: '',
  });

  const [mensajeEnviado, setMensajeEnviado] = useState(false);
  const [errorEnvio, setErrorEnvio] =useState(false)


  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const sendEmail = (e) => {
    e.preventDefault();

    const empresa = formData.user_name_empresa.trim();
    const nombre = formData.user_name.trim();
    const email = formData.user_email.trim();

    if (!empresa || !nombre || !email) {
      setError('Los campos con * son obligatorios.');
      return;
    }

    setError(null);

    emailjs
      .sendForm('service_1yhcfrs', 'template_sxw0ly3', form.current, 'O2IXULG70TO3ljCKi')
      .then((result) => {
        console.log('MENSAJE ENVIADO');
        setMensajeEnviado(true);

        // Restablecer los valores del formulario después del envío exitoso
        setFormData({
          user_name_empresa: '',
          user_name: '',
          user_email: '',
          user_fono: '',
          message: '',
          
        });
        setTimeout(() => {
          setMensajeEnviado(false);
        }, 3000); // 3 segundos (3000 milisegundos)
      })
      .catch((error) => {
        console.log('NO SE PUDO ENVIAR EL MENSAJE');
        setErrorEnvio(true);
        setTimeout(() => {
          setErrorEnvio(false);
        }, 3000); 
        
      });
    }

  return (
    <>
      <div className='title_form'>
      <h1 data-aos='zoom-in' data-aos-duration='500'>
            CONTÁCTANOS
        </h1>
       <img src={img_italy} alt='img_italy' className='img_italy' data-aos='zoom-in' data-aos-duration='500' />
      </div>
    

      <div className='formulario'>
        <div className='formulario_container' data-aos='fade-right' data-aos-duration='1000'>
          <div className='contacto'>
            <form className='form_action' ref={form} onSubmit={sendEmail}>
              <div className='label_form'>
                <label htmlFor='empresa'>Nombre de empresa *</label>
                <input
                  type='text'
                  id='empresa'
                  name='user_name_empresa'
                  value={formData.user_name_empresa}
                  onChange={handleChange}
                />
                {error && <p className='error'>{error}</p>}
              </div>
              <div className='label_form'>
                <label htmlFor='empresa'>Nombre y Apellido *</label>
                <input
                  type='text'
                  id='name'
                  name='user_name'
                  value={formData.user_name}
                  onChange={handleChange}
                />
                {error && <p className='error'>{error}</p>}
              </div>

              <div className='label_form'>
                <label htmlFor='email'>Correo electrónico *</label>
                <input
                  type='email'
                  id='email'
                  name='user_email'
                  value={formData.user_email}
                  onChange={handleChange}
                />
                {error && <p className='error'>{error}</p>}
              </div>

              <div className='label_form'>
                <label htmlFor='telefono'>Número de teléfono:</label>
                <input
                  type='text'
                  id='telefono'
                  name='user_fono'
                  value={formData.user_fono}
                  onChange={handleChange}
                />
              </div>

              <div className='label_form'>
                <label htmlFor='mensaje'>Mensaje:</label>
                <textarea id='mensaje' name='message' value={formData.message} onChange={handleChange} />
              </div>

              <button type='submit'>Enviar</button>
              {mensajeEnviado && <p className='alert_message'>Mensaje Enviado</p>}
              {errorEnvio && <p className='error_envio_message'>Hubo un error. Intente más tarde.</p>}


            </form>
          </div>
        </div>

        <div className='info_contacto'>
          <div className='info_container'>
            <div className='icon_contacto' data-aos='fade-left' data-aos-duration='1000'>
              <MDBIcon fas icon='map-marker-alt' style={{ color: '#ED7979', fontSize: '25px' }} />
              <p>Avenida Errázuriz 401, ciudad de Valparaíso</p>
            </div>
            <div className='icon_contacto' data-aos='fade-left' data-aos-duration='1000'>
              <MDBIcon fas icon='phone-alt' style={{ color: '#ED7979', fontSize: '25px' }} />
              <p>+56967305853</p>
            </div>
            <div className='icon_contacto' data-aos='fade-left' data-aos-duration='1000'>
              <MDBIcon far icon='envelope' style={{ color: '#ED7979', fontSize: '25px' }} />
              <p>comercial@aureofoods.cl</p>
            </div>
            <iframe
              src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3344.7437524633096!2d-71.63121832386747!3d-33.036880873556136!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9689e12cd8c6bc89%3A0x27bc45af605593b9!2zRXJyw6F6dXJpeiA0MDEsIFZhbHBhcmHDrXNv!5e0!3m2!1ses-419!2scl!4v1686683715499!5m2!1ses-419!2scl'
              alt='map'
              title='map'
              className='mapa'
              data-aos='fade-up'
              data-aos-duration='2000'
              data-aos-anchor-placement='top-bottom'
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Contacto;
