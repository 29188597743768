import React from 'react';
import './Footer.css'
import { MDBFooter, MDBContainer,} from 'mdb-react-ui-kit';
import {BsFacebook,BsWhatsapp,BsInstagram } from 'react-icons/bs';

export default function App() {
  return (
    <MDBFooter className='footer_footer text-center text-white'>
      <MDBContainer className='p-4 pb-0'>
        <section className='mb-4'>
           <a  href="https://www.facebook.com/AureoFoods-117022334809798" className='btn_logo'>
           <BsFacebook/>
            </a>
            <a href="https://wa.me/+56967305853" className='btn_logo'>
             <BsWhatsapp/>
            </a>
            <a href="https://www.instagram.com/aureofoods/" className='btn_logo'>
             <BsInstagram/>
            </a>
        </section>
      </MDBContainer>

      <div className='text-center p-3' >
        <a className='text-white' href='https://aureofoods.cl/'>
          AUREOFOODS SPA
        </a>
      </div>
    </MDBFooter>
  );
}
