import './App.css';
import NavBar from './components/NavBar/NavBar';
import Footer from './components/Footer/Footer';
import Home from './components/Home/Home';
import Servicios from './components/Servicios/Servicios';
import PuntosVentas from './components/PuntosVentas/PuntosVenta';
import Noticias from './components/Noticias/Noticias';
import Contacto from './components/Contacto/Contacto';

import { BrowserRouter, Routes,Route } from 'react-router-dom';

function App() {
  return (
    <div className="App">
<BrowserRouter>
     <NavBar/>
     <Routes>
     <Route path='/' element={<Home/>}/>
       <Route path='../Logo_Bruno.png' element={<Home/>}/>
       <Route path='/servicios' element={<Servicios/>}/>
       <Route path='/noticias' element={<Noticias/>}/>
       <Route path='/puntosventa' element={<PuntosVentas/>}/>
       <Route path='/contacto' element={<Contacto />} />
     </Routes>
     <Footer/>
</BrowserRouter> 

    </div>
  );
}

export default App;
