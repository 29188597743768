import React from 'react';
import './PuntosVenta.css';
import img_italy from '../../assets/img_italy2.png';

import calabaza_frente from '../../assets/frontis-calabaza.webp';
import {BsFacebook,BsWhatsapp,BsInstagram } from 'react-icons/bs';

const PuntosVentas = () => {
 
  return (
    <>
      <div className='container_container'>
        <div className="container_title">
          <h1> PUNTOS DE VENTA</h1>
          <img src={img_italy} alt='img_italy'  className='img_italy' />
        </div>
     
            <div className='punto_ventas'>

            <div className='punto_uno'>
              <img src={calabaza_frente} alt="Imagen 2" style={{ width: "200px" }} className='img_calabaza'/>
              <div className='punto_uno_container'>
              <h1> LA CALABAZA</h1>
              <p>Dirección: Prat 853, Valparaíso</p>
              <p>Horario: 09:00 a 18:00 Horas</p>
              <section className='mb-4'>
           <a  href="https://www.facebook.com/profile.php?id=100093680760700&mibextid=LQQJ4d" className='btn_logo_'>
           <BsFacebook/>
            </a>
            <a href="https://wa.me/+56994293501" className='btn_logo_'>
            <BsWhatsapp/>
            </a>
            <a href="https://www.instagram.com/brunofoods/" className='btn_logo_'>
            <BsInstagram/>
            </a>
        </section>
              </div>
            </div>
            </div>
       
      </div>
    </>
  )
}

export default PuntosVentas;
