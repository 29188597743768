import React, { useState } from 'react';
import './Servicios.css';
import empanadas from '../../assets/Imagen Empanadas.jpg';
import masa_pizza from '../../assets/mazapizza.webp';
import calzone from '../../assets/calzone.webp';
import eventos from '../../assets/eventos.webp';
import img_italy from '../../assets/img_italy2.png';
import {BsFacebook,BsWhatsapp,BsInstagram } from 'react-icons/bs';



const Servicios = () => {
  const [activeCardIndex, setActiveCardIndex] = useState(-1);

  const handleClick = (index) => {
    setActiveCardIndex((prevIndex) => (prevIndex === index ? -1 : index));
  };

  return (
    <>
      <div className="servicios_container">
        <div className="contenido_title">
          <h2>NUESTROS PRODUCTOS</h2>
          <img src={img_italy} width="300px" alt="Product" />
        </div>

        <div className="servicios">
          <div className={`hvr-float card ${activeCardIndex === 0 ? 'active' : ''}`}>
            <div className="icon">
              <img src={empanadas} alt="Spaceship" />
            </div>
            <div className="content" style={{ maxHeight: activeCardIndex === 2 ? 'none' : '200px', overflowY: 'scroll' }}>
            <h3>EMPANADAS</h3>
              <p>
              Empanada de masa fermentada 24 horas, para una experiencia “sin repeticiones” y pesadez estomacal, una maravilla al paladar. Nuestras variedades son las siguientes:              </p>
              <h2>Empanada de Pinolli:</h2>
              <p>
              Elaborado de masa fermentada 24 horas, relleno de carne, huevos, pasas, cebolla, y un toque secreto del chef en la masa.              </p>
              <h2>Empanada de quesolli:</h2>
              <p>
              Elaborado de masa fermentada 24 horas, queso mozzarella argentino y un toque secreto del chef en la masa. </p>
              <h2>Empanada de Alcayotelli:</h2>
              <p>Elaborado de masa fermentada 24 horas, alcayota, margarina, canela, azúcar flor y un toque secreto del chef en la masa.</p>
            </div>
            <button className="read_button" onClick={() => handleClick(0)}>
              {activeCardIndex === 0 ? 'Leer menos' : 'Leer más'}
            </button>
          </div>

          <div className={`hvr-float card ${activeCardIndex === 1 ? 'active' : ''}`}>
            <div className="icon">
              <img src={masa_pizza} alt="Spaceship" />
            </div>
            <div className="content">
            <h3>MASA DE PIZZA</h3>
              <p>
              Masa tradicional de 28 cm fermentada 24 horas para tener los mejores resultados. Todo lo necesario para agilizar la cocina de tu negocio. Nuestras variedades son las siguientes:
              </p>
              <h2>Masas de Pizza Tradicional Italiana (28 cm)</h2>
              <h2>Masas de Pizza Tradicional Italiana con Salsa (28 cm)</h2>
            </div>
            <button className="read_button" onClick={() => handleClick(1)}>
              {activeCardIndex === 1 ? 'Leer menos' : 'Leer más'}
            </button>
          </div>

          <div className={`hvr-float card ${activeCardIndex === 2 ? 'active' : ''}`}>
            <div className="icon">
              <img src={calzone} alt="Spaceship" />
            </div>
            <div className="content" style={{ maxHeight: activeCardIndex === 2 ? 'none' : '200px', overflowY: 'scroll' }}>
              <h3>CALZONE</h3>
              <p>
              Los “Calzone” son elaborados con masa tradicional Italiana Napolitana, de 67% de hidratación, 24 horas de fermentación previa, proporcionando un sabor, aroma y textura inigualable, con productos calidad premium, en formato individual. Nuestras variedades son las siguientes:</p>
                <h2>Calzone Capresse:</h2>
                <p>Elaborado con ingredientes 100% orgánicos, aceite de oliva, tomates  cherrys orgánicos multicolores, albahaca, coronado con salsa pomodoro natural y pincelado con color y fervor.</p> 
                <h2>Calzone Mechada Stout:</h2> 
                <p>Elaborado con carne mechada de larga cocción, hidratada con cerveza negra Stout artesanal tostada con notas ligeras a café con lupulación media, se mezcla con vegetales y salsa especial Stout, generando la base del sabor del producto, coronado con pincelado de salsa Stout en su exterior.</p>
                <h2>Calzone Ricotta Espinaca Champiñon:</h2> 
                <p>Elaborado con espinaca orgánica, champiñones de huerto y ricota casera, elaborado con leche entera, pimienta y aceite de oliva.</p> 
                <h2>Calzone Caponatta:</h2>
                <p>Elaborado con base Mozzarella, mix de pimentones, aceituna verde, cebolla, vinagre y aceite de oliva.</p> 
            </div>
            <button className="read_button" onClick={() => handleClick(2)}>
              {activeCardIndex === 2 ? 'Leer menos' : 'Leer más'}
            </button>
          </div>

          <div className={`hvr-float card ${activeCardIndex === 3 ? 'active' : ''}`}>
            <div className="icon">
              <img src={eventos} alt="Spaceship" />
            </div>
            <div className="content">
            <h3>BANQUETERÍA Y PEDIDOS MASIVOS</h3>         
            <p>Contáctenos para solicitar pedidos a escala, los detalles se acordarán por coordinación interna.</p>   
      
          
            </div>
            <div className='iconos_container'>
            <a  href="https://www.facebook.com/profile.php?id=100093680760700&mibextid=LQQJ4d" className='btn_logo_banqueteria'>
           <BsFacebook/>
            </a>
            <a href="https://wa.me/+56994293501"  className='btn_logo_banqueteria'>
            <BsWhatsapp/>
            </a>
            <a href="https://www.instagram.com/brunofoods/"  className='btn_logo_banqueteria'  target="_blank" rel="noopener noreferrer">
            <BsInstagram/>
            </a>
            </div> 
            <button className="read_button" onClick={() => handleClick(3)}>
              {activeCardIndex === 3 ? 'Leer menos' : 'Leer más'}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Servicios;