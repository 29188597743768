import React, { useEffect } from 'react';
import './carrousel.css'
import Glider from 'glider-js';
import { ArrowLeft, ArrowRight } from 'react-bootstrap-icons';
import img_italy from '../../assets/img_italy2.png'
import bros_cerveza from '../../assets/bros-cerveceria.webp'
import calabaza  from '../../assets/Calabaza.webp'
import cerveza_divino from '../../assets/cerveza-viejo-divino.webp'
import cerveza_popular from '../../assets/la-cerveceria-popular.webp'
import cerveza_destapas from '../../assets/destapas-beer-house.webp'
import aureotec from '../../assets/aureotec.webp'
import shoperia from '../../assets/Schoperia_4_y_medio_200x201.webp'
import resto_soto  from '../../assets/restaurant-barrio-sotomayor-200x200.webp'
import irlandes from '../../assets/cerveceria-irlandes.webp'



function Carrousel() {
  useEffect(() => {
    new Glider(document.querySelector('.carousel__lista'), {
      slidesToShow: 1,
      slidesToScroll: 1,
      dots: '.carousel__indicadores',
      arrows: {
        prev: '.carousel__anterior',
        next: '.carousel__siguiente'
      },
      responsive: [
        {
          // screens greater than >= 450px
          breakpoint: 450,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2
          }
        },
        {
          // screens greater than >= 800px
          breakpoint: 800,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 4
          }
        }
      ]
    });
  }, []);

  return (
    <div>
    <div className="clientes" data-aos="fade-up" data-aos-anchor-placement="center-bottom" data-aos-duration="2000">
      <h2>NUESTROS CLIENTES</h2>
      <img src={img_italy} className="img_italy" alt="img_italy" />
      <div className="carousel">
        <div className="carousel_clientes">
          <button aria-label="Anterior" className="carousel__anterior">
          <ArrowLeft />

         </button>

          <div className="carousel__lista">
            <div className="carousel__elemento">
              <img src={bros_cerveza} alt="Cliente1" width="150px" height="150px"/>
              <p>Bros Cerveceria</p>
            </div>
            <div className="carousel__elemento">
              <img src={calabaza} alt="Cliente2"  width="150px" height="150px" />
              <p>La Calabaza</p>
            </div>
            <div className="carousel__elemento">
              <img src={cerveza_divino} alt="cliente3"  width="150px" height="150px"/>
              <p>Cerveceria Viejo Divino</p>
            </div>
            <div className="carousel__elemento">
              <img src={cerveza_popular} alt="cliente4"  width="150px" height="150px" />
              <p>Cerveceria Popular</p>
            </div>
            <div className="carousel__elemento">
              <img src={cerveza_destapas} alt="cliente5"  width="150px" height="150px" />
              <p>Destapas Beer House</p>
            </div>
            <div className="carousel__elemento">
              <img src={aureotec} alt="cliente6"  width="150px" height="150px"  />
              <p>Aureotec</p>
            </div>
            <div className="carousel__elemento">
              <img src={shoperia} alt="cliente7"  width="150px" height="150px" />
              <p>Schoperia 4/2</p>
            </div>
            <div className="carousel__elemento">
              <img src={resto_soto} alt="cliente8" width="150px" height="150px"  />
              <p>Restaurant Barrio Sotomayor</p>
            </div>
            <div className="carousel__elemento">
              <img src={irlandes} alt="cliente9" width="150px" height="150px"  />
              <p>Cerveceria el Irlandes</p>
            </div>

          </div>
          
          <button aria-label="Siguiente" className="carousel__siguiente">
          <ArrowRight  />
          </button>
        </div>

        <div role="tablist" className="carousel__indicadores"></div>
      </div>
    </div>
    </div>
  );
}

export default Carrousel;
