import './Noticias.css'
import Carrousel_noticias from '../Carrousel_noticias/Carrousel_noticias'
import evento1 from '../../assets/BlackKitchen-2-_1_.webp'
import evento2 from '../../assets/Arriendo-de-Sala-Cocina-5.webp'
import evento3 from '../../assets/espinaca-champ.webp'
import img_italy from '../../assets/img_italy2.png'
import AOS from 'aos';
import 'aos/dist/aos.css';


const Noticias = () =>{
    AOS.init();

    return (
        <div className="blog_container">
            <div className="noticia_uno">
            <div className='titles_uno' data-aos="fade-right" data-aos-offset="300" data-aos-duration = "1000" data-aos-easing="ease-in-sine">
            <h2 style ={{color: "#7ED957"}}> DESCUBRE LOS SECRETOS</h2>
            <h2 style ={{color: "white"}}>DE LA AUTÉNTICA COCINA </h2>
            <h2 style ={{color: "#ED7979"}}>  ÍTALO-CHILENA</h2>
            </div>
           <div className='carrousel_container'>
            <Carrousel_noticias />
            </div>
            </div>
            <div className='noticia_dos' data-aos="fade-up"  data-aos-duration="2000">
            <div className='titles_dos'>
              <h2>EVENTOS Y MÁS DE AUREOFOODS</h2>
              <img src={img_italy} className='img_italy' alt='img_italy'/>
              </div>
                <div className='eventos_photo'  data-aos="fade-up"  data-aos-duration="2000">
                    <img src={evento1} className='img_eventos' alt='img_evento1'/>
                    <img src={evento2} className='img_eventos' alt='img_evento2' />
                    <img src={evento3} className='img_eventos' alt='img_evento3'/>
                </div>
            </div>
        </div>
    )
}

export default Noticias