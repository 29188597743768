import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { NavLink } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import Logo from '../../assets/Logo Aureofoods hd.png';
import './NavBar.css';


const NavBar = () => {
  return (
    <Navbar className='menu' expand="lg">
      <Container>
        <Navbar.Brand href="/">
          <img src={Logo} className="logo" alt="Logo" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" className='btn_toggler' />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto menu_nav">
            <NavLink exact to="/" activeClassName="active" className='menu_nav_a'>HOME</NavLink>
            <NavLink to="/servicios" activeClassName="active" className='menu_nav_a'>SERVICIOS</NavLink>
            <NavLink to="/noticias" activeClassName="active" className='menu_nav_a'>NOTICIAS</NavLink>
            <NavLink to="puntosventa" activeClassName="active" className='menu_nav_a'>PUNTOS DE VENTA</NavLink>
            <NavLink to="contacto" activeClassName="active" className='menu_nav_a'>CONTACTO</NavLink>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default NavBar;
