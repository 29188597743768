import { useState } from 'react';
import Carousel from 'react-bootstrap/Carousel';
import chef from '../../assets/chefs.jpeg'
import espinaca from '../../assets/Pulpo-Chimichurri.webp'
import caprese from '../../assets/Preparacion-de-Capresse.webp'
import  cocina from '../../assets/BlackKitchen-3.webp'
import '../Noticias/Noticias.css'

const Carrousel_noticias = () => {
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };

  return (
    <Carousel activeIndex={index} onSelect={handleSelect} className="carrousel-centered">
      <Carousel.Item>
        <img
          className="img_carrousel"
          src={chef}
          alt="First slide"
          width="400px"
        />
        <Carousel.Caption></Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="img_carrousel"
          src={espinaca}
          alt="Second slide"
          width="400px"
        />
        <Carousel.Caption></Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="img_carrousel"
          src={caprese}
          alt="Third slide"
          width="400px"
        />
        <Carousel.Caption></Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="img_carrousel"
          src={cocina}
          alt="Fourth slide"
          width="400px"
        />
        <Carousel.Caption></Carousel.Caption>
      </Carousel.Item>
    </Carousel>
  );
}

export default Carrousel_noticias;
